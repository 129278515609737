import SkinColorTemplate from '@/modules/questionnaire/components/steps/common/identity/skin-color/SkinColorTemplate';

import { SKIN_COLOR, GENDER } from '@/modules/questionnaire/api/constants';
import {
  SKIN_COLOR_WITH_MIDDLE_HAIR_VALUES,
  SKIN_COLOR_WITH_LIGHT_HAIR_VALUES,
  SKIN_COLOR_WITH_DARK_HAIR_VALUES
} from '@/modules/questionnaire/components/steps/common/identity/skin-color/skinColorValues';

export default {
  title: 'Steps/Identity/SkinColor/SkinColorTemplate',
  component: SkinColorTemplate
};

const createStory = props => () => ({
  components: { SkinColorTemplate },
  storyProps: props,
  wrapperStyles: {
    display: 'flex',
    justifyContent: 'center'
  },
  template:
    '<div :style="$options.wrapperStyles"><skin-color-template v-bind="$options.storyProps" /></div>'
});

export const MiddleColorLightBrownSkin = createStory({
  gender: GENDER.MALE,
  skinColor: SKIN_COLOR.LIGHT_BROWN,
  skinColorAllowedValues: SKIN_COLOR_WITH_MIDDLE_HAIR_VALUES
});

export const DarkHairWhiteSkin = createStory({
  gender: GENDER.MALE,
  skinColor: SKIN_COLOR.WHITE,
  skinColorAllowedValues: SKIN_COLOR_WITH_DARK_HAIR_VALUES
});

export const LightHairPaleWhiteSkin = createStory({
  gender: GENDER.MALE,
  skinColor: SKIN_COLOR.PALE_WHITE,
  skinColorAllowedValues: SKIN_COLOR_WITH_LIGHT_HAIR_VALUES
});
