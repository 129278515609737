<template>
  <div class="skin-color q-w360 q-fit">
    <face-features
      class="skin-color__image"
      :gender="gender"
      :skin="skinColorImageValue"
      is-skin-picture
    />

    <question-with-error-wrap class="q-mt24 q-mt32-md" :error="getFieldsError(['skinColor'])">
      <single-answer-question
        title="label.mySkinColor"
        :value="skinColor"
        :options="skinColorOptions"
        @input="onFieldChange('skinColor', $event)"
        @option-mouseover="onOptionMouseOver('skinColor', $event)"
        @option-mouseleave="onOptionMouseLeave"
      />
    </question-with-error-wrap>
  </div>
</template>

<script>
import FaceFeatures from '@/modules/questionnaire/new-design-components/FaceFeatures';
import SingleAnswerQuestion from '@/modules/questionnaire/new-design-components/questions/SingleAnswerQuestion';
import QuestionWithErrorWrap from '@/modules/questionnaire/new-design-components/questions/QuestionWithErrorWrap';

import { stepTemplateMixin } from '@/modules/questionnaire/mixins/stepTemplateMixin';

import { SKIN_COLOR } from '@/modules/questionnaire/api/constants';

const SKIN_COLOR_OPTIONS = [
  { value: SKIN_COLOR.VERY_PALE_WHITE, text: 'skinColor.veryPaleWhite' },
  { value: SKIN_COLOR.PALE_WHITE, text: 'skinColor.paleWhite' },
  { value: SKIN_COLOR.WHITE, text: 'skinColor.white' },
  { value: SKIN_COLOR.LIGHT_BROWN, text: 'skinColor.lightBrown' },
  { value: SKIN_COLOR.MEDIUM_BROWN, text: 'skinColor.mediumBrown' },
  { value: SKIN_COLOR.DARK_BROWN, text: 'skinColor.darkBrown' },
  { value: SKIN_COLOR.BLACK, text: 'skinColor.black' }
];

export default {
  name: 'SkinColorTemplate',
  components: { FaceFeatures, SingleAnswerQuestion, QuestionWithErrorWrap },
  mixins: [stepTemplateMixin],
  props: {
    gender: {
      type: String,
      required: true
    },
    skinColor: {
      type: String,
      required: true
    },
    skinColorAllowedValues: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      mouseOverField: null
    };
  },
  computed: {
    skinColorOptions() {
      return SKIN_COLOR_OPTIONS.filter(({ value }) => this.skinColorAllowedValues.includes(value));
    },
    skinColorImageValue() {
      const { fieldName, fieldValue } = this.mouseOverField || {};

      return fieldName === 'skinColor' ? fieldValue : this.skinColor;
    }
  },
  methods: {
    onOptionMouseOver(fieldName, fieldValue) {
      this.mouseOverField = { fieldName, fieldValue };
    },
    onOptionMouseLeave() {
      this.mouseOverField = null;
    }
  }
};
</script>

<style lang="scss" scoped>
@import '~universkin-shared/src/assets/styles/scss/sizes.scss';

.skin-color {
  &__image {
    width: 80px;
    height: 143px;
    margin: 0 auto;
  }
}

@media (min-width: $desktop-start) {
  .skin-color {
    &__image {
      width: 85px;
      height: 154px;
    }
  }
}
</style>
